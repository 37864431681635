import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import '../assets/main.css'
import heroStyles from "../components/hero.module.css";
import Compare from './compare-components/dropdown-compare.js'
import Breadcrumbs from './Breadcrumbs'
import Loan from './compare-components/loan-svg.js'

class Template extends React.Component {
  
  
  render() {
    //const { children } = this.props

    return (
      <div style={{ background: '#fff' }}> {/* Taking this out ruins navbar color for some reason */}
      <Navigation />

      {this.props.title ? 
      <div className="bg-gray-600 w-full">
        <div class="relative z-0 text-center rounded shadow-md flex card justify-center" style={{zIndex: "1", justify: "center",}}>
            <div class="bg-cover h-72 rounded-l-sm" alt="Room Image" style={{height: "360px", background: `url(${require("../assets/img/11.jpg")})`, filter: 'blur(1px)', filter: 'brightness(30%)', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%'}}></div>

            <div class="absolute content-center bottom-0 p-2 hover:opacity-100">
                <h1 className = "text-right py-3 px-6 m-3 pb-10 text-white font-bold text-4xl mt-1 mb-2 text-left">
                    {this.props.title}
                </h1>
            </div>
        </div>
    </div> : <></>}
      <Breadcrumbs url={this.props.location.pathname}/>
      <Container>
      <div className="flex grid-cols-5" style={{flexWrap: 'wrap',}}>
                    <div className="lg:w-4/5 w-full">
                        {this.props.mainContent}
                        
                     </div>
                    <div className="lg:w-1/5 w-full z-0" style={{zIndex: "1"}}>
                     
                           {this.props.sideContent}
                       
                    </div> 
      </div>
      </Container>

    </div>
    )
  }
}

export default Template